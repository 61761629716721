import axios from 'axios'

import { getUserLocalStorage } from '../context/auth_provider/util'

export const axiosFileAPI = axios.create({
   baseURL: 'https://api.solenfermagem.com',
  //baseURL: 'http://77.37.126.41:3333/',
  // baseURL: 'http://89.213.142.127:4000', 
  // baseURL: 'http://:3333/', // produção
})

axiosFileAPI.interceptors.request.use(
    (config) => {
      const user = getUserLocalStorage()

      if (user && user.token) {
        config = {
          ...config,
          headers: {
            Authorization: user.token,
            ContentType: 'multipart/form-data',
          },
        }
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    },
)
