import { useEffect, useState } from 'react'
import { PDFViewer, Document, View, Page, Text, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer'
import { format } from 'date-fns'
import logo         from '../../../../../../../images/sol_logo.png'
import logoHospital from '../../../../../../../images/logohospitaltelecila.jpg'
import alergia from '../../../../../../../images/alergia.png'
import isolamento from '../../../../../../../images/isolamentoPreto.png'
import lesao from '../../../../../../../images/lesao.png'
import ocular from '../../../../../../../images/ocular.png'
import queda from '../../../../../../../images/queda.png'
import { useAuth } from '../../../../../../../context/auth_provider/useAuth'
import { IPlanoCuidadosRelatorio } from '../../../../../../../api/relatorios/types'
import { relatorioPlanoCuidados } from '../../../../../../../api/relatorios/relatoriosAPI'
import Button from '../../../../../../../components/Button'
import { isDesktop } from 'react-device-detect'
import { IUser } from '../../../../../../../context/auth_provider/types'


const styles = StyleSheet.create({
  dataHeader: {
    position: 'absolute',
    top: 2,
    textAlign: 'right',
    right: 5,
    fontSize: 10,
  },

  HeaderStyled: {
    marginTop: 22,
    display: 'flex',
    flexDirection: 'row'
  },

  ContainerStyled: {
    border: '1px solid #ddd',
    borderRadius: 5,
    padding: '15 10 10',
    position: 'relative',
    margin: '20 20',
  },

  LabelStyled: {
    fontSize: 10,
    fontWeight: 400,
  },
  
  ValueStyled: {
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 8,
  },
  ImagesStyle: {
    position: "absolute",
    top: 12,
    left: 16,
    height: 50,
    width: 60,
    marginLeft: 15,
  },
  ImagesStyle2: {
    position: 'absolute',
    top: 4,
    right: 16,
    height: 50,
    width: 50,
    marginRight: 15,
  },

  Sumario: {
    marginLeft: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  SumarioStyled: {
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0,
  },

  DadosSumarioStyled: {
    fontSize: 10,
    fontWeight: 500,
    letterSpacing: 0,
  },

  TituloStyled: {
    position: 'absolute',
    top: -8,
    left: 8,
    paddingLeft: 4,
    paddingRight: 4,
    backgroundColor: '#fff',
    fontSize: 12,
    fontWeight: 700,
  }
})

interface IPlanoCuidadosRelatorioCamposProps {
  idPaciente: number,
}

interface IDocumentProps {
  dadosRelatorio?: IPlanoCuidadosRelatorio,
  user: IUser,
}

const Relatorio = (props: IDocumentProps) => {
  const solucao = {
    "INTEGRIDADE_FISICA": [
      "LESÃO POR PRESSÃO NO ADULTO",
      "PROTEÇÃO INEFICAZ",
      "RISCO DE INFECÇÃO",
      "RISCO DE INFECÇÃO NO SÍTIO CIRÚRGICO",
      "INTEGRIDADE DA MEMBRANA MUCOSA ORAL PREJUDICADA",
      "RISCO DE INTEGRIDADE DA MEMBRANA MUCOSA ORAL PREJUDICADA",
      "INTEGRIDADE DA PELE PREJUDICADA",
      "RISCO DE INTEGRIDADADE DA PELE PREJUDICADA",
      "INTEGRIDADE TISSULAR PREJUDICADA",
      "RISCO DE INTEGRIDADE TISSULAR PREJUDICADA",
      "RISCO DE LESÃO POR PRESSÃO NO ADULTO",
      "MOBILIDADE NO LEITO PREJUDICADA",
      "MOBILIDADE FÍSICA PREJUDICADA",
      "MOBILIDADE PREJUDICADA COM CADEIRA DE RODAS",
      "CAPACIDADE DE TRANSFERÊNCIA PREJUDICADA",
      "SENTAR-SE PREJUDICADO",
      "LESÃO POR PRESSÃO NO ADULTO",
      "PROTEÇÃO INEFICAZ",
      "RISCO DE INFECÇÃO",
      "RISCO DE INFECÇÃO NO SÍTIO CIRÚRGICO",
      "INTEGRIDADE DA MEMBRANA MUCOSA ORAL PREJUDICADA",
      "RISCO DE INTEGRIDADE DA MEMBRANA MUCOSA ORAL PREJUDICADA",
      "INTEGRIDADE DA PELE PREJUDICADA",
      "RISCO DE INTEGRIDADADE DA PELE PREJUDICADA",
      "INTEGRIDADE TISSULAR PREJUDICADA",
      "RISCO DE INTEGRIDADE TISSULAR PREJUDICADA",
      "RISCO DE LESÃO POR PRESSÃO NO ADULTO",
      "MOBILIDADE NO LEITO PREJUDICADA",
      "MOBILIDADE FÍSICA PREJUDICADA",
      "MOBILIDADE PREJUDICADA COM CADEIRA DE RODAS",
      "CAPACIDADE DE TRANSFERÊNCIA PREJUDICADA",
      "SENTAR-SE PREJUDICADO"
    ],
    "FUNCAO_RESPIRATORIA": [
      "PADRÃO RESPIRATÓRIO INEFICAZ",
      "TROCA DE GASES PREJUDICADA",
      "RESPOSTA DISFUNCIONAL AO DESMAME VENTILATÓRIO DO ADULTO",
      "VENTILAÇÃO ESPONTÂNEA",
      "DESOBSTRUÇÃO INEFICAZ DE VIAS AÉREAS",
      "PADRÃO RESPIRATÓRIO INEFICAZ",
      "RISCO DE ASPIRAÇÃO",
      "PADRÃO RESPIRATÓRIO INEFICAZ",
      "TROCA DE GASES PREJUDICADA",
      "RESPOSTA DISFUNCIONAL AO DESMAME VENTILATÓRIO DO ADULTO",
      "VENTILAÇÃO ESPONTÂNEA",
      "DESOBSTRUÇÃO INEFICAZ DE VIAS AÉREAS",
      "PADRÃO RESPIRATÓRIO INEFICAZ",
      "RISCO DE ASPIRAÇÃO"
    ],
    "SEG_FISICA_EMOCIONAL": [
      "RISCO DE QUEDAS NO ADULTO",
      "PROCESSOS FAMILIARES DISFUNCIONAIS",
      "PROCESSOS FAMILIARES INTERROMPIDOS",
      "ANSIEDADE",
      "ANSIEDADE RELACIONADA A MORTE",
      "MEDO",
      "RISCO DE REAÇÃO ALÉRGICA",
      "CONTROLE EMOCIONAL LÁBIL",
      "DESESPERANÇA",
      "SÍNDROME DE ESTRESSE POR MUDANÇA",
      "SÍNDROME PÓS-TRAUMA",
      "TRISTEZA CRÔNICA",
      "RISCO DE QUEDAS NO ADULTO",
      "PROCESSOS FAMILIARES DISFUNCIONAIS",
      "PROCESSOS FAMILIARES INTERROMPIDOS",
      "ANSIEDADE",
      "ANSIEDADE RELACIONADA A MORTE",
      "MEDO",
      "RISCO DE REAÇÃO ALÉRGICA",
      "CONTROLE EMOCIONAL LÁBIL",
      "DESESPERANÇA",
      "SÍNDROME DE ESTRESSE POR MUDANÇA",
      "SÍNDROME PÓS-TRAUMA",
      "TRISTEZA CRÔNICA"
    ],
    "EXAME_FISICO": [
      "RISCO DE GLICEMIA INSTÁVEL",
      "HIPOTERMIA",
      "HIPERTERMIA",
      "RISCO DE GLICEMIA INSTÁVEL",
      "HIPOTERMIA",
      "HIPERTERMIA",
      "RISCO DE PRESSÃO ARTERIAL INSTÁVEL"
    ],
    "PERCEPCAO_SENTIDOS": [
      "DOR AGUDA",
      "DOR CRÔNICA",
      "CONFORTO PREJUDICADO",
      "DOR AGUDA",
      "DOR CRÔNICA",
      "CONFORTO PREJUDICADO"
    ],
    "SONO_REPOUSO": [
      "INSÔNIA",
      "DISTÚRBIO NO PADRÃO DE SONO",
      "PRIVAÇÃO DE SONO",
      "INSÔNIA",
      "DISTÚRBIO NO PADRÃO DE SONO",
      "PRIVAÇÃO DE SONO"
    ],
    "FUNCAO_NEUROLOGICA": [
      "CONFUSÃO AGUDA",
      "RISCO DE CONFUSÃO AGUDA",
      "CONFUSÃO CRÔNICA",
      "MEMÓRIA PREJUDICADA",
      "RISCO DE RESSECAMENTO OCULAR",
      "AUTOGESTÃO INEFICAZ DO RESSECAMENTO OCULAR",
      "RISCO DE LESÃO NA CÓRNEA",
      "CONFUSÃO AGUDA",
      "RISCO DE CONFUSÃO AGUDA",
      "CONFUSÃO CRÔNICA",
      "MEMÓRIA PREJUDICADA",
      "RISCO DE RESSECAMENTO OCULAR",
      "AUTOGESTÃO INEFICAZ DO RESSECAMENTO OCULAR",
      "RISCO DE LESÃO NA CÓRNEA"
    ],
    "FUNCAO_CARDIOVASCULAR": [
      "RISCO DE CHOQUE",
      "VOLUME DE LÍQUIDOS EXCESSIVO",
      "VOLUME DE LÍQUIDOS DEFICIENTE",
      "DÉBITO CARDÍACO DIMINUÍDO",
      "RISCO DE DÉBITO CARDÍACO DIMINUÍDO",
      "RISCO DE FUNÇÃO CARDIOVASCULAR PREJUDICADA",
      "RISCO DE PERFUSÃO TISSULAR CARDÍACA DIMINUÍDA",
      "RISCO DE PERFUSÃO TISSULAR CEREBRAL INEFICAZ",
      "PERFUSÃO TISSULAR CEREBRAL INEFICAZ",
      "RISCO DE PERFUSÃO TISSULAR PERIFÉRICA INEFICAZ",
      "RISCO DE PRESSÃO ARTERIAL INSTÁVEL",
      "RISCO DE TROMBOSE",
      "RISCO DE CHOQUE",
      "VOLUME DE LÍQUIDOS EXCESSIVO",
      "VOLUME DE LÍQUIDOS DEFICIENTE",
      "DÉBITO CARDÍACO DIMINUÍDO",
      "RISCO DE DÉBITO CARDÍACO DIMINUÍDO",
      "RISCO DE FUNÇÃO CARDIOVASCULAR PREJUDICADA",
      "RISCO DE PERFUSÃO TISSULAR CARDÍACA DIMINUÍDA",
      "RISCO DE PERFUSÃO TISSULAR CEREBRAL INEFICAZ",
      "PERFUSÃO TISSULAR CEREBRAL INEFICAZ",
      "RISCO DE PERFUSÃO TISSULAR PERIFÉRICA INEFICAZ",
      "RISCO DE PRESSÃO ARTERIAL INSTÁVEL",
      "RISCO DE TROMBOSE",
      "PERFUSÃO TISSULAR PERIFÉRICA INEFICAZ"
    ],
    "CUIDADO_CORPORAL": [
      "DÉFICIT NO AUTOCUIDADO PARA O BANHO",
      "DÉFICIT NO AUTOCUIDADO PARA O BANHO",
      "DÉFICIT NO AUTOCUIDADO PARA ALIMENTAÇÃO",
      "DÉFICIT NO AUTOCUIDADO PARA HIGIENE ÍNTIMA",
      "DÉFICIT NO AUTOCUIDADO PARA VESTIR-SE",
      "DISPOSIÇÃO PARA AUTOCUIDADO MELHORADA",
      "AUTONEGLIGÊNCIA"
    ],
    "REGISTRO_COMPLEMENTAR": [
      "SÍNDROME DE ABSTINÊNCIA DE SUBSTÂNCI AGUDA",
      "RISCO DE COMPORTAMENTO SUICIDA",
      "SÍNDROME DE ABSTINÊNCIA DE SUBSTÂNCI AGUDA",
      "RISCO DE COMPORTAMENTO SUICIDA",
      "RISCO DE VÍNCULO PREJUDICADO",
      "PROCESSOS FAMILIARES DISFUNCIONAIS ",
      "RECUPERAÇÃO CIRÚRGICA RETARDADA ",
      "RISCO DE RECUPERAÇÃO CIRÚRGICA RETARDADA"
    ],
    "ELIMINACAO_FISIOLOGICA": [
      "RETENÇÃO URINÁRIA",
      "RETENÇÃO URINÁRIA",
      "CONSTIPAÇÃO",
      "Risco de constipação ",
      "RISCO DE LESÃO NO TRATO URINÁRIO",
      "VOLUME DE LÍQUIDOS DEFICIENTE",
      "RISCO DE VOLUME DE LÍQUIDOS DEFICIENTE",
      "RISCO DE DESEQUILÍBRIO DIMINUÍDO",
      "ELIMINAÇÃO URINÁRIA PREJUDICADA",
      "INCONTINÊNCIA URINÁRIA MISTA",
      "RISCO DE RETENÇÃO URINÁRIA",
      "DIARREIA",
      "MOTILIDADE GASTROINTESTINAL PREJUDICADA",
      "RISCO DE MOTILIDADE GASTROINTESTINAL PREJUDICADA"
    ],
    "NUTRICAO": [
      "Nutrição desequilibrada: menor que as necessidades corporais ",
      "Volume de líquidos deficiente ",
      "Deglutição prejudicada ",
      "Risco de glicemia instável ",
      "NUTRIÇÃO DESEQUILIBRADA: MENOR QUE AS NECESSIDADES CORPORAIS",
      "VOLUME DE LÍQUIDOS DEFICIENTE",
      "DEGLUTIÇÃO PREJUDICADA",
      "RISCO DE GLICEMIA INSTÁVEL",
      "RISCO DE ASPIRAÇÃO ",
      "NÁUSEA ",
      "DISPOSIÇÃO PARA NUTRIÇÃO MELHORADA",
      "RISCO DE VOLUME DE LÍQUIDO DEFICIENTE ",
      "RISCO DE DESEQUILÍBRIO ELETROLÍTICO"
    ],
    "SEXUALIDADE": [
      "DISFUNÇÃO SEXUAL"
    ],
    "COMUNICACAO_EDUCACAO": [
      "DISPOSIÇÃO PARA LETRAMENTO EM SAÚDE MELHORADO",
      "CONHECIMENTO DEFICIENTE",
      "COMUNICAÇÃO VERBAL PREJUDICADA",
      "DISPOSIÇÃO PARA COMUNICAÇÃO MELHORADA"
    ],
    "RELIGIOSIDADE_ESPIRITUALIDADE": [
      "RELIGIOSIDADE PREJUDICADA",
      "RISCO DE RELIGIOSIDADE PREJUDICADA",
      "DISPOSIÇÃO PARA RELIGIOSIDADE MELHORADA",
      "SOFRIMENTO ESPIRITUAL",
      "RISCO DE SOFRIMENTO ESPIRITUAL "
    ],
    "TERAPIA_ENDOVENOSA": [
      "RISCO DE TRAUMA VASCULAR",
      "RISCO DE REAÇÃO ALÉRGICA",
      "INTEGRIDADE DA PELE PREJUDICADA",
      "RISCO DE INFECÇÃO"
    ],
    "CONTROLE_INFUSAO": [
      "RISCO DE SANGRAMENTO ",
      "RISCO DE TRAUMA VASCULAR",
      "RISCO DE REAÇÃO ALÉRGICA",
      "INTEGRIDADE DA PELE PREJUDICADA ",
      "RISCO DE INTEGRIDADE DA PELE PREJUDICADA",
      "RISCO DE CHOQUE",
      "RISCO DE PERFUSÃO TISSULAR CARDÍACA DIMINUÍDA",
      "RISCO DE PERFUSÃO TISSULAR CEREBRAL INEFICAZ",
      "PERFUSÃO TISSULAR PERIFÉRICA INEFICAZ ",
      "RISCO DE PERFUSÃO TISSULAR PERIFÉRICA INEFICAZ",
      "RISCO DE PRESSÃO ARTERIAL INSTÁVEL",
      "RISCO DE TROMBOSE",
      "RISCO DE FUNÇÃO HEPÁTICA PREJUDICADA",
      "RISCO DE DESEQUILÍBRIO ELETROLÍTICO "
    ]
  } 
  console.log(props.dadosRelatorio)
  return (
    <Document>
      {props.dadosRelatorio && (
        <Page size='A4' style={{ paddingBottom: '50px' }}>
          <View fixed>
            <Text style={styles.dataHeader}>
              {format((new Date()), 'dd/MM/yyyy hh:mm:ss')}
            </Text> 
            <View style={styles.HeaderStyled}>
            <Image src={require("../../../../../../../images/solamarelo.png")}  style={styles.ImagesStyle} cache />   
              <View style={[styles.Sumario, {flex:1, justifyContent: 'center', alignItems: 'center'}]}>
                <Text style={styles.SumarioStyled}>
                  {(props.dadosRelatorio.dhPlanoCuidado) ? 'PLANO DE CUIDADOS DE ENF.' : 'ADMISSÃO DE ENFERMAGEM'}
                </Text>
                <Text style={styles.DadosSumarioStyled}>{props.user?.enderecoEmpresa || '-'}</Text>
                <Text style={styles.DadosSumarioStyled}>{props.user?.contatoEmpresa || '-'}</Text>

                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
                  <View style={{ ...styles.ContainerStyled, marginRight: 0, marginLeft: 8, marginBottom: 0 }}>
                    <Text style={{ ...styles.TituloStyled, color: '#C4C4C4' }}>Riscos</Text>

                    <View style={{ display: 'flex', flexDirection: 'row', minWidth: 80 }}>
                       {props.dadosRelatorio.riscoLesaoPressao && (
                        <Image src={require("../../../../../../../images/lesao.png")} style={{ width:15,height:15, marginRight: '10px' }} />
                      )}

                      {props.dadosRelatorio.riscoRessecamentoOcular && (
                        <Image src={require("../../../../../../../images/ocular.png")} style={{ width:15,height:15, marginRight: '10px' }} />
                      )}

                      {props.dadosRelatorio.riscoAlergia && (
                        <Image src={require("../../../../../../../images/alergia.png")} style={{ width:15,height:15, marginRight: '10px' }} />
                      )}

                      {props.dadosRelatorio.riscoQueda && (
                        <Image src={require("../../../../../../../images/queda.png")} style={{ width:15,height:15, marginRight: '10px' }} />
                      )}

                      {props.dadosRelatorio.riscoContagio && (
                        <Image src={require("../../../../../../../images/isolamento.png")} style={{ width:15,height:15, marginRight: '10px' }} />
                      )} 
                    </View>
                  </View>
                </View>
              </View>            
              <Image src={require("../../../../../../../images/logohospitaltelecila.png")} style={styles.ImagesStyle2} />
            </View>

            <View style={styles.ContainerStyled}>
              <Text style={styles.TituloStyled}>Paciente</Text>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Text style={{ fontSize: '14px' }}>{props.dadosRelatorio.paciente.nome}</Text>
                <Text style={{ fontSize: '10px' }}>Idade: {props.dadosRelatorio.paciente.idade || ''}</Text>
                <Text style={{ fontSize: '10px' }}>Contato: {props.dadosRelatorio.paciente.contato || ''}</Text>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '4px'}}>
                <Text style={{ fontSize: '10px' }}>
                  Data de admissão: {props.dadosRelatorio.dhAdmissao ? format((new Date(props.dadosRelatorio.dhAdmissao)), 'dd/MM/yyyy hh:mm') : ''}
                </Text>
                <Text style={{ fontSize: '10px' }}>Dias de internação: {props.dadosRelatorio.diasInternacao}</Text>
                <Text style={{ fontSize: '10px' }}>Prontuário: {props.dadosRelatorio.id}</Text>
              </View>
            </View>
          </View>
            
          {props.dadosRelatorio.prontuarioPlanoCuidado.map(planoCuidado => (
            <View style={{ ...styles.ContainerStyled, marginTop: '0px' }}>
              <View>
                <Text style={styles.LabelStyled}>
                  Diagnóstico de enfermagem:
                </Text>

                <Text style={styles.ValueStyled}>
                  {solucao[planoCuidado.grupo][planoCuidado.idDiagnostico]}  
                  {/* {planoCuidado.diagnostico.descricao} */}
                </Text>
              </View>

              <View>
                <Text style={{ ...styles.LabelStyled, marginBottom: '8px' }}>
                  Resultado(s) de enfermagem:
                </Text>

                <View style={{ display: 'flex', flexDirection: 'row', marginBottom: '4px'}}>
                  <Text style={{...styles.LabelStyled, width: '200px', textAlign: 'center'}}>Descrição</Text>
                  <Text style={{...styles.LabelStyled, width: '200px', textAlign: 'center'}}>Indicador</Text>
                  <Text style={{...styles.LabelStyled, width: '100px', textAlign: 'center'}}>Padrão</Text>
                  <Text style={{...styles.LabelStyled, width: '100px', textAlign: 'center'}}>Registrado</Text>
                </View>
                {planoCuidado.prontuarioPlanoCuidadoResultado.map(dado => (
                  <View style={{ display: 'flex', flexDirection: 'row'}} key={dado.id}>
                    <Text style={{...styles.ValueStyled, width: '200px'}}>{dado.resultado.descricao}</Text>
                    <Text style={{...styles.ValueStyled, width: '200px'}}>{dado.indicador.nome}</Text>
                    <Text style={{...styles.ValueStyled, width: '100px', textAlign: 'center'}}>{dado.esperado}</Text>
                    <Text style={{...styles.ValueStyled, width: '100px', textAlign: 'center'}}>{dado.valor}</Text>
                  </View>
                ))}
              </View>

              <View>
                <Text style={{ ...styles.LabelStyled, marginBottom: '8px', marginTop: '12px' }}>
                  Intervenção(ões) de enfermagem:
                </Text>

                <View style={{ display: 'flex', flexDirection: 'row', marginBottom: '4px'}}>
                  <Text style={{...styles.LabelStyled, width: '200px', textAlign: 'center'}}>Intervenção</Text>
                  <Text style={{...styles.LabelStyled, width: '200px', textAlign: 'center'}}>Atividade</Text>
                  <Text style={{...styles.LabelStyled, width: '200px', textAlign: 'center'}}>Aprazamento</Text>
                  <Text style={{...styles.LabelStyled, width: '200px', textAlign: 'center'}}>Registros/OBS</Text>
                </View>
                {planoCuidado.prontuarioPlanoCuidadoAtividade.map(dado => (
                  <View style={{ display: 'flex', flexDirection: 'row'}} key={dado.id}>
                    <Text style={{...styles.ValueStyled, width: '200px'}}>{dado.intervencao.nome}</Text>
                    <Text style={{...styles.ValueStyled, width: '200px'}}>{dado.atividade.descricao}</Text>
                    <Text style={{...styles.ValueStyled, width: '200px', textAlign: 'center'}}>{dado.aprazamento.nome}</Text>
                    <Text style={{ width: '200px' }} />
                  </View>
                ))}
              </View>
            </View>
          ))}

          <Text style={{ textAlign: 'center', marginBottom: 4 }}>
            _______________________________
          </Text>
          <Text style={{ fontSize: 12, fontWeight: 400, textAlign: 'center', marginBottom: 4 }}>
            {props.user.nome || ''}
          </Text>

          <Text style={{ textAlign: 'center', marginBottom: 0, fontSize: 10, fontWeight: 400 }}>
            Assinatura/Carimbo
          </Text>
        </Page>
      )}
    </Document>
  )
}

const PlanoCuidadosRelatorioCampos = (props: IPlanoCuidadosRelatorioCamposProps) => {
  const [dadosRelatorio, setDadosRelatorio] = useState<IPlanoCuidadosRelatorio>()
  const { user } = useAuth()

  const getDadosRelatorio = () => {
    relatorioPlanoCuidados(props.idPaciente)
      .then(resultado => setDadosRelatorio(resultado))
      .catch(console.error)
  }

  useEffect(() => {
    getDadosRelatorio()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.idPaciente])

  return (
    <>
      <Button variant='new' style={{ marginBottom: '16px' }}>
        <PDFDownloadLink
          document={
            <Relatorio dadosRelatorio={dadosRelatorio} user={user} />
          }
          fileName="rel-plano-cuidados.pdf"
          style={{ textDecoration: 'none', color: '#fff' }}
        >
          {({ loading }) =>
            loading ? 'Gerando seu documento...' : 'Fazer download do relatório de plano de cuidados!'
          }
        </PDFDownloadLink>
      </Button>

      {isDesktop && (
        <PDFViewer width='100%' height='80%' showToolbar>
          <Relatorio dadosRelatorio={dadosRelatorio} user={user} />
        </PDFViewer>
      )}
    </>
  )
}

export {PlanoCuidadosRelatorioCampos }
export default PlanoCuidadosRelatorioCampos
